// Imports
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/base/Index.vue"),
      children: [
        {
          path: "/",
          name: "Home",
          component: () => import("@/views/home/Home.vue")
        },
        {
          path: "/sign-up",
          component: () => import("@/views/home/SignUp.vue")
        },
        {
          path: "/solution",
          component: () => import("@/views/home/Solution.vue")
        },
        {
          path: "/recruit",
          component: () => import("@/views/home/Recruit.vue")
        },
        {
          path: "/about-us",
          component: () => import("@/views/home/AboutUs.vue")
        },
        {
          path: "/register-agreement",
          component: () => import("@/views/home/RegisterAgreement.vue")
        },
      
        {
          path: "/success",
          component: () => import("@/views/home/Success.vue")
        },
        {
          path: "/activation",
          component: () => import("@/views/home/activation.vue")
        },
        {
          path: "/question",
          component: () => import("@/views/home/question.vue")
        },

        {
          path: "/template-index",
          component: () => import("@/views/template/index.vue")
        },
        {
          path: "/template-list",
          component: () => import("@/views/template/list.vue")
        },
        {
          path: "/template-detail",
          component: () => import("@/views/template/detail.vue")
        },
        {
          path: "/user-index",
          component: () => import("@/views/template/user_index.vue"),
          meta: {
            needLogin: true
          }
        },
        {
          path: "/balance",
          component: () => import("@/views/template/balance.vue"),
          meta: {
            needLogin: true
          }
        },
        {
          path: "/buyed",
          component: () => import("@/views/template/buyed.vue"),
          meta: {
            needLogin: true
          }
        },
        {
          path: "/income",
          component: () => import("@/views/template/income.vue"),
          meta: {
            needLogin: true
          }
        },
        {
          path: "/withdrawal",
          component: () => import("@/views/template/withdrawal.vue"),
          meta: {
            needLogin: true
          }
        },
        {
          path: "/consume",
          component: () => import("@/views/template/consume.vue"),
          meta: {
            needLogin: true
          }
        },
        {
          path: "/template-detail-custom",
          component: () => import("@/views/template/detail_custom.vue"),
          meta: {
            needLogin: true
          }
        },
        {
          path: "/privacy-policy",
          component: () => import("@/views/article/PrivacyPolicy.vue")
        },
        {
          path: "/consumer-agreement",
          component: () => import("@/views/article/ConsumerAgreement.vue")
        },
        {
          path: "/terms-service",
          component: () => import("@/views/article/TermsService.vue")
        },
        {
          path: "/TOS",
          component: () => import("@/views/article/TOS.vue")
        },
        {
          path: "/user-agreement",
          component: () => import("@/views/article/UserAgreement.vue")
        },

      ]
    },
    {
      path: "/sign-in",
      component: () => import("@/views/SignIn.vue")
    },
  ]
});
router.beforeEach(function(to, from, next) {
  if (to.meta.needLogin) {
    //页面是否登录
    if (localStorage.getItem("DROPSHOPUSERINFO")) {

      next(); //表示已经登录
    } else {
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        name: "Home"
      });
    }
  } else {
    //表示不需要登录
    next(); //继续往后走
  }
});
export default router;
